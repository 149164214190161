import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
	return (
		<footer className="py-5 bg-secondary ">
			<Container>
				<Row className="text-center  align-items-md-center">
					<Col xs={12} lg="auto">
						<StaticImage
							src="../images/logo.png"
							style={{ maxWidth: "300px" }}
							placeholder="traced_svg"
							formats={["auto", "webp"]}
							quality="100"
						/>
					</Col>
					<Col className="py-5 py-lg-3">
						<ul className="text-left list-unstyled text-center pt-4 text-lg-right">
							<li className="d-md-inline-block mr-md-3 pb-3 pb-md-0">
								<Link className="footer-link  " to="/about">
									<p>About Us</p>
								</Link>
							</li>
							<li className="d-md-inline-block pl-md-6 pl-xl-6 pl-lg-4 pb-3 pb-md-0 ">
								<Link className="footer-link  pt-4" to="/testimonials">
									<p>Reviews</p>
								</Link>
							</li>
							<li className="d-md-inline-block pl-md-6 pl-xl-6 pl-lg-4 pb-3 pb-md-0">
								<Link className="footer-link " to="/#services">
									<p>Services</p>
								</Link>
							</li>
							<li className="d-md-inline-block pl-md-6 pl-xl-6 pl-lg-4">
								<Link className="footer-link  " to="/contact-us">
									<p>Contact Us</p>
								</Link>
							</li>
						</ul>
					</Col>
				</Row>
				<Row className="pb-5 align-items-center pb-lg-3">
					<Col className="text-center text-lg-right">
						<Link
							style={socialIcons}
							target="_blank"
							className="social-icon footer-link mt-0 pt-0 pr-2 "
							href="https://www.linkedin.com/in/roger-prime-b17023188"
						>
							<FaLinkedin />
						</Link>
						<Link
							style={socialIcons}
							target="_blank"
							className="social-icon footer-link mt-0 pt-0 pl-2 "
							href="https://www.facebook.com/primesparkselec"
						>
							<FaFacebookSquare />
						</Link>
					</Col>
				</Row>

				<hr className="w-100 bg-white" />
				<Row className="text-center text-lg-right">
					<Col>
						<Link
							className="footer-link "
							href="https://www.rjm.digital/web-design-eastbourne"
						>
							<p>Website designed by RJM Digital</p>
						</Link>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

const socialIcons = {
	fontSize: "3rem",
};

export default Footer;
