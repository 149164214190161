import { Link } from "gatsby";
import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";

const Navigation = ({ bgColor, variant }) => {
	const bgColorNew = bgColor || "white";
	const variantColor = variant || "light";
	return (
		<Navbar
			className="px-xl-6 py-4"
			bg={bgColorNew}
			variant={variantColor}
			expand="lg"
		>
			<Container>
				<Navbar.Brand as={Link} to="/">
					<StaticImage
						src="../images/logo.png"
						className="logo"
						placeholder="traced_svg"
						quality="100"
						formats={["auto", "webp"]}
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto pt-lg-3 pt-xl-0">
						<Nav.Link className="px-3 px-xl-4" as={Link} to="/">
							Home
						</Nav.Link>
						<Nav.Link className="px-3 px-xl-4" as={Link} to="/about">
							About
						</Nav.Link>
						<NavDropdown
							className="px-3 px-xl-4"
							title="Services"
							id="collasible-nav-dropdown"
						>
							<NavDropdown.Item as={Link} to="/services/electric-vehicle">
								Electric Vehicle
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/services/domestic">
								Domestic
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/services/commercial">
								Commercial
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/services/inspections">
								Inspections
							</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link className="px-3 px-xl-4" as={Link} to="/testimonials">
							Testimonials
						</Nav.Link>
						<Nav.Link
							className="px-3 px-xl-4 text-primary montserrat-semi-bold"
							as={Link}
							to="/contact-us"
						>
							Contact Us
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
};

export default Navigation;
